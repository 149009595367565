// import React, { useState, useCallback, useRef } from 'react';
// import InfoCardWarren from './warrenpetercard';

// // Move analysisTypes outside the component to prevent recreation
// const analysisTypes = ['Buffett', 'ONeil', 'Graham', 'OShaughnessy'];

// const CardFWarren = ({ symbol }) => {
//   const [results, setResults] = useState({});
//   const [loading, setLoading] = useState(false);
//   const previousSymbol = useRef('');
//   const [error, setError] = useState(null);

//   const performAnalysis = useCallback(async (ticker) => {
//     // Skip if already loading or if the symbol is the same as the previous one
//     if (!ticker || loading || ticker === previousSymbol.current) {
//       console.log('Skipping analysis:', { ticker, loading, previousSymbol: previousSymbol.current });
//       return;
//     }
    
//     setLoading(true);
//     setError(null);
//     previousSymbol.current = ticker;
//     try {
//       console.log('Making request to backend for ticker:', ticker);
//       // const response = await fetch('http://localhost:8000/analyze_all', {
//       const response = await fetch('/api/analyze_all', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json',
//         },
//         body: JSON.stringify({
//           ticker: ticker,
//           api_key: "MZNUxALEyarHKD7VgwIFq9BntSNq9MjA"
//         }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       console.log('Received data from backend:', data);
      
//       if (!data || typeof data !== 'object') {
//         throw new Error('Invalid response format from server');
//       }
//       setResults(data);
//     } catch (error) {
//       console.error('Error fetching analysis:', error);
//       setError(error.message);
//       const errorResults = {};
//       analysisTypes.forEach(type => {
//         errorResults[type] = { error: 'Failed to fetch data', details: error.message };
//       });
//       setResults(errorResults);
//     } finally {
//       setLoading(false);
//     }
//   }, [loading]);

//   React.useEffect(() => {
//     console.log('Symbol changed to:', symbol);
//     if (symbol && symbol !== previousSymbol.current) {
//       performAnalysis(symbol);
//     }
//   }, [symbol, performAnalysis]);

//   if (!symbol) {
//     return (
//       <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
//         <div className="p-6 text-center text-gray-500 dark:text-gray-400">
//           Enter a stock symbol to view analysis
//         </div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
//         <div className="p-6 text-center text-red-500 dark:text-red-400">
//           Error: {error}
//         </div>
//       </div>
//     );
//   }

//   console.log('Current results:', results);
//   return (
//     <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
//       <section className="m-4">
//         <header className="border-b border-gray-200 dark:border-gray-700 p-4">
//           <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
//             Guru Analysis for {symbol}
//           </h2>
//         </header>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
//           {analysisTypes.map((type) => (
//             <InfoCardWarren
//               key={type}
//               title={type}
//               loading={loading}
//               conditionsMet={results[type]?.conditions_met || 0}
//               totalConditions={results[type]?.total_conditions || 0}
//               percentage={results[type]?.percentage || 0}
//             />
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default CardFWarren;


import React, { useState, useCallback, useRef } from 'react';
import InfoCardWarren from './warrenpetercard';

// Move analysisTypes outside the component to prevent recreation
const analysisTypes = ['Buffett', 'ONeil', 'Graham', 'OShaughnessy', 'Lynch'];

// Add analysis descriptions
const analysisDescriptions = {
    'Buffett': `Warren Buffett's Investment Criteria:
    • Return on Equity > 15%
    • Debt to Equity < 0.5
    • Current Ratio > 1.5
    • Consistent Earnings Growth > 10%
    • Net Profit Margin > 10%
    • P/E Ratio < 25
    • Operating Margin > Industry Average
    • Net Profit Margin > Industry Average
    • Sustainable Growth Rate > 15%

    Analysis based on company's financial strength, profitability, and growth metrics using TTM (Trailing Twelve Months) data.`,

    'ONeil': `William O'Neil's CANSLIM Criteria:
    • Current Quarterly Earnings Growth > 25%
    • Price Strength (Price > 50-day Moving Average)
    • Industry Position (Positive Price Change)
    • Market Leadership (Market Cap > $1B, Volume > 500k)
    • Market Direction (Price > 200-day Moving Average)

    Focus on strong earnings growth, price momentum, and market leadership using current market data.`,

    'Graham': `Benjamin Graham's Value Criteria:
    • Market Cap > $100M
    • Current Ratio > 2
    • Positive EPS for Past 5 Years
    • Current Dividend Yield > 0
    • Positive 5-Year Earnings Growth
    • P/E Ratio < 15
    • Price to Book < 1.5

    Conservative approach focusing on financial stability and value metrics using TTM data.`,

    'OShaughnessy': `James O'Shaughnessy's Criteria:
    • Market Cap > $150M
    • Price to Sales Ratio < 1.5
    • Positive Operating Cash Flow
    • Trading Volume > 500k Daily
    • Positive Price Momentum (YTD)

    Focuses on market value, trading volume, and momentum factors using current market data.`,

    'Lynch': `Peter Lynch's Investment Criteria:
    • Not in Finance Sector
    • P/E Ratio < 20
    • P/E vs Growth Rate (PEG Analysis)
    • Dividend-Adjusted PEG ≥ 0.5
    • Company Size & Stability (Market Cap > $2B)
    • Debt to Equity < 1.0
    • Consistent Earnings Growth

    Emphasizes company growth relative to valuation using both historical and current data.`
};

const CardFWarren = ({ symbol }) => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const previousSymbol = useRef('');
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState({});

  const toggleInfo = (type) => {
    setShowInfo(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const performAnalysis = useCallback(async (ticker) => {
    // Skip if already loading or if the symbol is the same as the previous one
    if (!ticker || loading || ticker === previousSymbol.current) {
      console.log('Skipping analysis:', { ticker, loading, previousSymbol: previousSymbol.current });
      return;
    }
    
    setLoading(true);
    setError(null);
    previousSymbol.current = ticker;

    try {
      console.log('Making request to backend for ticker:', ticker);
      
      // Get the base URL from environment variable or use default
      const baseUrl = process.env.REACT_APP_API_URL || '/api';
      const response = await fetch(`${baseUrl}/analyze_all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include', // Important for CORS
        body: JSON.stringify({
          ticker: ticker,
          api_key: "MZNUxALEyarHKD7VgwIFq9BntSNq9MjA"
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received data from backend:', data);
      
      if (!data || typeof data !== 'object') {
        throw new Error('Invalid response format from server');
      }
      setResults(data);
    } catch (error) {
      console.error('Error fetching analysis:', error);
      setError(error.message);
      const errorResults = {};
      analysisTypes.forEach(type => {
        errorResults[type] = { 
          error: 'Failed to fetch data', 
          details: error.message,
          conditions_met: 0,
          total_conditions: 0,
          percentage: 0
        };
      });
      setResults(errorResults);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  React.useEffect(() => {
    console.log('Symbol changed to:', symbol);
    if (symbol && symbol !== previousSymbol.current) {
      performAnalysis(symbol);
    }
  }, [symbol, performAnalysis]);

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
        <div className="p-6 text-center text-gray-500 dark:text-gray-400">
          Enter a stock symbol to view analysis
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-dark-card w-full overflow-hidden rounded-lg shadow-lg">
      <section className="m-4">
        <header className="border-b border-gray-200 dark:border-gray-700 p-4">
          <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
            {error ? (
              <span className="text-red-500">Error: {error}</span>
            ) : (
              `Guru Analysis for ${symbol}`
            )}
          </h2>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 p-4">
          {analysisTypes.map((type) => (
            <div key={type} className="relative">
              <button
                onClick={() => toggleInfo(type)}
                className="absolute top-2 right-2 z-10 w-6 h-6 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none"
                title={`Show ${type} analysis details`}
              >
                i
              </button>
              {showInfo[type] && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full m-4 max-h-[80vh] overflow-hidden">
                    <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
                      <h3 className="text-lg font-semibold">{type} Analysis Criteria</h3>
                      <button
                        onClick={() => toggleInfo(type)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        ×
                      </button>
                    </div>
                    <div className="p-4 overflow-y-auto max-h-[calc(80vh-8rem)]">
                      <p className="text-sm whitespace-pre-line">
                        {analysisDescriptions[type]}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <InfoCardWarren
                title={type}
                loading={loading}
                conditionsMet={results[type]?.conditions_met || 0}
                totalConditions={results[type]?.total_conditions || 0}
                percentage={results[type]?.percentage || 0}
                error={results[type]?.error}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CardFWarren;